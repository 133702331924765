import React from 'react';
import { PlayCircleOutlined,DeleteOutlined,PauseCircleOutlined } from '@ant-design/icons';
import { Button, Row, Col, Progress, Slider } from 'antd';
import Amplify, {Storage} from 'aws-amplify';
import { camelCase } from 'lodash';

const audioPlayerStyle = {
  
  position:'absolute',
  bottom:'20px',
  background:'rgba(70,70,70,.60)',
  width: 'calc(100vw - 60px)',
  borderRadius:'10px',
  padding:'10px'

}

const cloudFrontUrl = 'http://d1tx1xcsirchw2.cloudfront.net/';
let currentAudio = null;
class AudioPlayerPublic extends React.Component {
    state = {  }
    audioRef = React.createRef();
    constructor(props){
        super(props);



        this.state = {
            fileUrl: cloudFrontUrl + props.fileUrl,
           
            playaudioprogressstring:'00:00',
            playaudioprogress:0,
            playaudiodurationstring:'00:00',
            playaudioduration:0,
        }



       
    }

    formatNumber = (number)=>{
      return number < 10 ? '0'+number.toString() : number.toString();
    }

    onLoadedMetadata = (event) => {
      if(this.audioRef.current){
        const seconds = this.audioRef.current.duration;
        const m = this.formatNumber(Math.floor(seconds/60));
        const s = this.formatNumber(Math.floor(seconds - m*60));
          this.setState({playaudiodurationstring: m+':'+s, playaudioduration: this.audioRef.current.duration});
      }
     
    }

    audioProgress = (event) =>{
        
        const seconds = this.audioRef.current.currentTime;
        const m = this.formatNumber(Math.floor(seconds/60));
        const s = this.formatNumber(Math.floor(seconds - m*60));
 
 
        this.setState({playaudioprogressstring: m+':'+s, playaudioprogress:seconds});
        
    }

   

    startAudio = () =>{
        this.setState({audioPlayed:true});
        const audio = this.audioRef.current;
        currentAudio=this.state.fileUrl;
        
        audio.play();
    }

    pauseAudio = () => {
      const audio = this.audioRef.current;
        audio.pause();
      this.setState({audioPlayed:false});
    }

    static getDerivedStateFromProps(nextProps, prevState){
      
      return {
        fileUrl: cloudFrontUrl + nextProps.fileUrl
       };
    }

    componentDidUpdate(){
      if(currentAudio != this.state.fileUrl){
        const audio = this.audioRef.current;
        audio.load();
        this.startAudio();
      }
     
    }

    render() { 



        return ( 
            
            <div style={audioPlayerStyle}>
              <div className="rec-timer">
              {this.state.fileUrl && ( <audio ref={this.audioRef} onLoadedMetadata={this.onLoadedMetadata} onTimeUpdate={this.audioProgress}>
                      <source src={this.state.fileUrl} type="audio/mp3"/>
                  </audio>)}
              </div>
              <Row>
                <Col xs={6}></Col>
                <Col xs={12} >
                  <Row>
                  {!this.state.audioPlayed && (<Col xs={12} align="right"><Button shape="circle" onClick={this.startAudio}><PlayCircleOutlined /></Button></Col>)}
                    {this.state.audioPlayed && (<Col xs={12} align="right"><Button shape="circle" onClick={this.pauseAudio}><PauseCircleOutlined /></Button></Col>)}
                  </Row>
                  <Row justify="center" align="top">
                  <Col xs={2} style={{paddingTop:'12px'}} justify="right">
                        <span>{this.state.playaudioprogressstring}</span>    
                    </Col>  
                    <Col xs={20}>
                      <Slider
                          value={(this.state.playaudioprogress/this.state.playaudioduration)*100} 
                          showInfo={false} onChange={(value)=> {
                          this.audioRef.current.currentTime = this.state.playaudioduration*value/100;
                          }
                          }/>
                    </Col> 
                    <Col xs={2} style={{paddingTop:'12px'}}>
                        <span>{this.state.playaudiodurationstring}</span>    
                    </Col>   
                  </Row>
                 
                </Col>
                <Col xs={6}></Col>
              </Row>
              
              
             
              </div>
                   
         );
    }
}
 
export default AudioPlayerPublic;