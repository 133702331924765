/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNotificaEmail = /* GraphQL */ `
  mutation CreateNotificaEmail($input: CreateNotificheEmailInput!) {
    createNotificaEmail(input: $input) {
      PK
      SK
      datatime
      email
      risposta
      sfondo
      prova
      rigioca
      createdAt
      updatedAt
    }
  }
`;
export const createNotificheEmail = /* GraphQL */ `
  mutation CreateNotificheEmail(
    $input: CreateNotificheEmailInput!
    $condition: ModelNotificheEmailConditionInput
  ) {
    createNotificheEmail(input: $input, condition: $condition) {
      PK
      SK
      datatime
      email
      risposta
      sfondo
      prova
      rigioca
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificheEmail = /* GraphQL */ `
  mutation UpdateNotificheEmail(
    $input: UpdateNotificheEmailInput!
    $condition: ModelNotificheEmailConditionInput
  ) {
    updateNotificheEmail(input: $input, condition: $condition) {
      PK
      SK
      datatime
      email
      risposta
      sfondo
      prova
      rigioca
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotificheEmail = /* GraphQL */ `
  mutation DeleteNotificheEmail(
    $input: DeleteNotificheEmailInput!
    $condition: ModelNotificheEmailConditionInput
  ) {
    deleteNotificheEmail(input: $input, condition: $condition) {
      PK
      SK
      datatime
      email
      risposta
      sfondo
      prova
      rigioca
      createdAt
      updatedAt
    }
  }
`;
