import React, {useState} from 'react';
import { Layout, Grid,Menu, Row, Col} from 'antd';
import { connect, Provider } from 'react-redux';
import navigationConfig from "configs/NavigationConfig";
import utils from 'utils';
import AppPubViews from 'views/app-public-views';
import AudioPlayerPublic from 'components/shared-components/AudioPlayerPublic';
import store from 'redux/store';
import SearchInput from 'components/layout-components/NavSearch/SearchInput';
import NavSearch from 'components/layoutpub-components/NavSearch';

const { Content,Header,Footer } = Layout;


export const PubLayout = ({ location,playAudioFile }) => {
    
    const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
    const showPlayer = playAudioFile !== '';
    
    return (

    <Layout>
      {/* <Header>
        <div className="logo" style={{float:'left'}}>
          <img src='/img/logo-white.png' alt='civiglio-logo'/>  
        </div>
        <NavSearch />
        </Header> */}
      <Content>
        {/* <div style={{padding:'30px'}}>{playAudioFile} */}
        <AppPubViews location={location} />
          {/* {showPlayer && <AudioPlayerPublic fileUrl={playAudioFile}/>} */}
        {/* </div> */}
        </Content>
      {/* <Footer>Footer</Footer> */}
    </Layout>
     
      
     
        
      )
}



export default React.memo(PubLayout);