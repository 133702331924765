import React from 'react';
import moment from 'moment';
import ReactDOM from 'react-dom';
// Stackoverflow: https://stackoverflow.com/questions/10756313/javascript-jquery-map-a-range-of-numbers-to-another-range-of-numbers
const  mapNumber = (number, in_min, in_max, out_min, out_max)=> {
	return (number - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
  }


const SVGCircle = ({ radius }) => (
	<svg className='countdown-svg'>
		<path fill="none" stroke="#333" strokeWidth="4" d={describeArc(50, 50, 48, 0, radius)}/>
	</svg>
);

// From stackoverflow: https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
const polarToCartesian=(centerX, centerY, radius, angleInDegrees) =>{
	var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;
  
	return {
	  x: centerX + (radius * Math.cos(angleInRadians)),
	  y: centerY + (radius * Math.sin(angleInRadians))
	};
  }
  
  const describeArc=(x, y, radius, startAngle, endAngle)=>{
  
	  var start = polarToCartesian(x, y, radius, endAngle);
	  var end = polarToCartesian(x, y, radius, startAngle);
  
	  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
  
	  var d = [
		  "M", start.x, start.y, 
		  "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
	  ].join(" ");
  
	  return d;       
  }
  
class Countdown extends React.Component {
	state = {
		monts: undefined,
		days: undefined,
		hours: undefined,
		minutes: undefined,
		seconds: undefined
	}
	
	componentDidMount() {
		this.interval = setInterval(() => {
			const { timeTillDate, timeFormat } = this.props;
			const then = moment(timeTillDate, timeFormat);
			const now = moment();
			const countdown = moment(then - now);
			const monts = countdown.format('M');
			const days = countdown.format('D');
			const hours = countdown.format('HH');
			const minutes = countdown.format('mm');
			const seconds = countdown.format('ss');

			this.setState({ monts,days, hours, minutes, seconds });
		}, 1000);
	}

	componentWillUnmount() {
		if(this.interval) {
			clearInterval(this.interval);
		}
	}
	
	render() {
		const { monts,days, hours, minutes, seconds } = this.state;
		const montsRadius = mapNumber(monts, 12, 0, 0, 360);
		const daysRadius = mapNumber(days, 30, 0, 0, 360);
		const hoursRadius = mapNumber(hours, 24, 0, 0, 360);
		const minutesRadius = mapNumber(minutes, 60, 0, 0, 360);
		const secondsRadius = mapNumber(seconds, 60, 0, 0, 360);

		if(!seconds) {
			return null;
		}
		
		return (
			<div>
				<h3 style={{ borderRadius: 75 , padding: 20 ,color: 'black', backgroundColor: "rgb(183,183,183,0.24)" }} >Al lancio ufficiale mancano</h3>
				<div className='countdown-wrapper'>
				{monts && (
						<div className='countdown-item'>
							<SVGCircle radius={montsRadius} />
							{(monts-1)} 
							<span>mesi</span>
						</div>
					)}
					{days && (
						<div className='countdown-item'>
							<SVGCircle radius={daysRadius} />
							{days} 
							<span>giorni</span>
						</div>
					)}
					{hours && (
						<div className='countdown-item'>							
							<SVGCircle radius={hoursRadius} />
							{hours} 
							<span>ore</span>
						</div>
					)}
					{minutes && (
						<div className='countdown-item'>
							<SVGCircle radius={minutesRadius} />
							{minutes} 
							<span>minuti</span>
						</div>
					)}
					{seconds && (
						<div className='countdown-item'>
							<SVGCircle radius={secondsRadius} />
							{seconds} 
							<span>secondi</span>
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default Countdown;