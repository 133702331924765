/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:371ac329-f68e-4bfb-8127-2186d9569b62",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_zoaicDPdH",
    "aws_user_pools_web_client_id": "1821qir4rjgeijk48ur7jvdrnd",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://2m7mrb57urbx3gw5mnzfenkgbm.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6c3exnz6mnemne3ghst3y3yhdi",
    "aws_mobile_analytics_app_id": "a190e67ddd0841f9b850b761689660e4",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
