import React from 'react'
import moment from 'moment';
import Amplify, { API, graphqlOperation} from 'aws-amplify'
import { createNotificaEmail } from '../../../graphql/mutations'
import Countdown from './countdown';
import awsExports from "../../../aws-exports";
import { InvalidConfigError } from 'react-intl';
import { Menu } from 'antd';
import { indexOf } from 'lodash';
Amplify.configure(awsExports);


const	 myArray = ['Zumpano.jpg','spiaggia-della-rotonda-tropea.jpg','museo_all_aperto_cosenza_corso_mazzini.jpg',
		'Piazza_Plebiscito.jpg' ,'Venezia-panoramica.jpg','Colosseo-a-Roma.jpg', 'CorsoMazzini.jpeg', 
		'torre-di-pisa.jpg','marechiaro.jpg','NapoliVesuvio.jpg'];  

const formStates = {rigioca: 'no', email: '', sfondo: myArray[(Math.random() * myArray.length) | 0],datatime :'', risposta:'' }

class ComingSoon extends React.Component {
	showInfo = 'inline';
	state = { };


	//const v =  await  API.graphql(graphqlOperation(sendmailAMD, {input: ''}))


	constructor(props){
		super(props);


		if(window.location.href.indexOf('?')>0){
         console.log('trovato');
	//	 API.graphql(graphqlOperation(sendmailAMD, {input: ''}))

	const search = window.location.search;
	const params = new URLSearchParams(search);
	const rig = params.get('rigioca');

	formStates.rigioca='yes';
	formStates.email = rig+'@rigioca.it';

	console.log('rigioca='+rig+'@rigioca.it')

	this.state = {
		dat: Date.now(),
		formState :formStates,
		imgsfondo: myArray[(Math.random() * myArray.length) | 0],
		emailvisible:false,
		textButton:'Rigioca!',
	}
	
} else {

	this.state = {
		dat: Date.now(),
		formState :formStates,
		imgsfondo: myArray[(Math.random() * myArray.length) | 0],
		emailvisible: true,
		textButton:'Giochiamo!',
	}

}

		

	}

	setInput(key, value) {
		formStates[key]=value;
		this.setState({ formState:formStates })
	  }
	
	    addnotify= async()=>  {
		try {
		  if (!this.state.formState.email ) return

		  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		  if (!pattern.test(this.state.formState.email)) {
			
			alert( "Inserire un'email Valida.");
			return

		  }
		
		  this.state.formState.datatime =  moment().format("DD-MM-YYYY HH:mm:ss");
		  const notifica = { ...this.state.formState }
		  const r =  await  API.graphql(graphqlOperation(createNotificaEmail, {input: notifica}))
		  if(r){
		
			console.log(r.data.createNotificaEmail.PK);
			
			alert('Grazie per aver giocato con noi! Controlla la tua email, ti faremo sapere se hai indovinato. E nel frattempo… continua a viaggiare giocando con noi! Molla gli ormeggi, è tempo di esplorare!');
		
			window.location.href = "/?rigioca="+r.data.createNotificaEmail.PK;

			//	this.setState({dat: Date.now(),emailvisible:false,formState:f, textButton:'Rigioca' });

/*			setTimeout(()=>{
				this.setState({imgsfondo: f.sfondo});
			
			},300) //*/
			//document.getElementById('emailshow').style.display = 'none';
			//this.state.formState.sfondo =myArray[(Math.random() * myArray.length) | 0];
			//document.getElementById('sfondo').style.backgroundImage = 'url(/img/sfondo/'+this.state.formState.sfondo+')';
			//document.getElementById('rispsfondo').value ='';
			//document.getElementById('bottoninvia').innerHTML ='Rigioca';
		
			
		  }


		} catch (err) {
		  console.log('error creating Notify:', err)
		  console.log('awsExports file ',awsExports);
		}
	  }


/*	componentDidMount() {

		const	 myArray = ['Zumpano.jpg','spiaggia-della-rotonda-tropea.jpg','museo_all_aperto_cosenza_corso_mazzini.jpg',
		'Piazza_Plebiscito.jpg' ,'Venezia-panoramica.jpg','Colosseo-a-Roma.jpg', 'CorsoMazzini.jpeg', 
		'torre-di-pisa.jpg'];  
		const sfondo = myArray[(Math.random() * myArray.length) | 0];
		this.setState({sfondo});
		this.interval = setInterval(() => {
		const	sfondo = myArray[(Math.random() * myArray.length) | 0];			
			this.setState({sfondo});
		}, 10000);
	}

	componentWillUnmount() {
		if(this.interval) {
			clearInterval(this.interval);
		}
	}
	//*/
  
    render() { 

        return ( 
        <>
<div id="sfondo" className="coming-soon-page " style={{background: 'url(/img/sfondo/'+this.state.formState.sfondo+'?dt='+this.state.dat+')'}}>
<div id="logo" style={{position:'absolute',height:85,width:300, background: 'url(/img/civigliologo.png', backgroundSize: '240px', backgroundRepeat:'no-repeat'}}> </div> 
<div className="container">
<div className="row">
<div className="col-lg-8 col-lg-offset-2">


				<h1 style={{ color: "rgba(255,255,255,0.9)",  borderRadius: 75 , padding: 20 , backgroundColor: "rgb(183,183,183,0.24)" }} > Nessuno saprà farti viaggiare come Civiglio!</h1>

				
				<div id="countdown" className="margin-top-10 margin-bottom-35"></div>
	<Countdown 
		timeTillDate="04 01 2022, 12:00 am" 
		timeFormat="MM DD YYYY, h:mm a" 
	/>
				<br/>

				<h3 style={{ fontSize: 22 ,borderRadius: 75 , padding: 20 ,color: 'black', backgroundColor: "rgb(183,183,183,0.24)" }}>Nell’attesa iniziamo a viaggiare con la fantasia!<br></br>
Hai riconosciuto l’immagine di sfondo?<br></br>
Se la risposta è sì compila il modulo sottostante, riceverai subito un'email di conferma!<br></br>
Se la risposta è no prova a ricaricare la pagina, ti daremo un’altra possibilità<br></br>
</h3>

				<div className="main-search-input gray-style margin-top-30 margin-bottom-10 divnotifica">
					<div id="emailshow" className="main-search-input-item" style={{ display: this.showInfo }}>
						<input type="text" placeholder="La tua email" defaultValue={this.state.formState.email} style={{display:this.state.emailvisible?'block':'none'}}  onChange={event => this.setInput('email', event.target.value)} />
					</div>
					<div className="main-search-input-item imgsfondo">
	
						<input id="rispsfondo" type="text" placeholder="La tua risposta"  defaultValue={this.state.formState.risposta} value={this.state.formState.risposta}  onChange={event => this.setInput('risposta', event.target.value)}/>
					</div>
					<button id="bottoninvia" onClick={this.addnotify} className="button">{this.state.textButton}</button>
				</div>
    </div>
    </div>
</div>
</div>
            </>
           );
    }
}
 
export default ComingSoon;