import { Input } from 'antd'
import React, { Component } from 'react'

const { Search } = Input;

export default class NavSearch extends Component {
    render() {
        return (
            <div style={{marginTop:'10px'}}>
            <Search
              placeholder="input search text"
              onSearch={value => console.log(value)}
              style={{ width: 200 }}
            />
                </div>
            
        )
    }
}
