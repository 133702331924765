import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
// import { HashRouter as Router } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import ComingSoon from './views/app-public-views/pubhome/comingsoon';

function App() {
  return (
    <div className="App">
      {/* <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" component={Views}/>
          </Switch>
        </Router>
      </Provider> */}
<ComingSoon></ComingSoon>

    </div>
  );
}

export default App;
