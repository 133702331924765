import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PUBLIC_PATH } from 'configs/AppConfig'

export const AppPubViews = (props) => {
 
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PUBLIC_PATH}/`} component={lazy(() => import(`./pubhome`))} /> 
       
        {/* <Route path={`${APP_PUBLIC_PATH}/pubhome/detail/:id`} component={lazy(() => import(`./pubhome/detail`))} /> */}
        <Redirect from={``} to={`${APP_PUBLIC_PATH}/`} />
      </Switch>
    </Suspense>
  )
}

export default AppPubViews;